import React from 'react';
import '../styles/Header/header.css'; 

function HeaderImage() {
  // Array of header image filenames
  const headerImages = [
    'CombatBox_MUSTANG_modROEN_version_2.png',
    'CombatBox_No410_SQN_RCAF_-_Riksen.png',
    'CombatBox_TEMPEST_modROEN.png',
    'CombatBox-RoachFinal.jpg',
    'head_1.a5efc5804214.jpg',
    'image3A8809_Glitch2.jpg'
    // Add more image filenames as needed
  ];

  // Randomly select an image from the array
  const randomImage = headerImages[Math.floor(Math.random() * headerImages.length)];

  return (
    <div className="header-container">
      <img
        src={`${process.env.PUBLIC_URL}/images/header-images/${randomImage}`}
        alt="Combat Box"
        className="header-image"
      />
      <div className="header-text">
        <h1>Combat Box</h1>
        <h2>Apollo Beta 18 "Big Normandy"</h2>
      </div>
    </div>
  );
}

export default HeaderImage;
