import React from 'react';
import PropTypes from 'prop-types';

function DisplayWarEffort({ campaignData }) {
    return (
        <div className="war-effort-container">
            WAR EFFORT
            <div className='border p-2'>
                <div className='d-flex'>
                <div className='col-6'>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th  className="bg-transparent text-white"scope="row">Allies</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Controlled Objectives</th>
                                <td className="bg-transparent text-white">{campaignData.AlliedControlledPointsToday}</td>
                            </tr>
                            <tr className='border-bottom'>
                                <th className="bg-transparent text-white"scope="row">Available Supply</th>
                                <td className="bg-transparent text-white">{campaignData.AlliedSupplyToday}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Airframes</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAllied.Aircraft}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Pilots</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAllied.Pilots}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Vehicles</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAllied.Vehicles}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Buildings</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAllied.Buildings}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Ships</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAllied.Ships}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-6 border-start ps-2'>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Axis</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Controlled Objectives</th>
                                <td className="bg-transparent text-white">{campaignData.AxisControlledPointsToday}</td>
                            </tr>
                            <tr className='border-bottom'>
                                <th className="bg-transparent text-white"scope="row">Available Supply</th>
                                <td className="bg-transparent text-white">{campaignData.AxisSupplyToday}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Airframes</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAxis.Aircraft}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Pilots</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAxis.Pilots}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Vehicles</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAxis.Vehicles}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Buildings</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAxis.Buildings}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white"scope="row">Lost Ships</th>
                                <td className="bg-transparent text-white">{campaignData.LossesAxis.Ships}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>
    );
}

DisplayWarEffort.propTypes = {
    campaignData: PropTypes.shape({
        AlliedControlledPointsToday: PropTypes.number.isRequired,
        AxisControlledPointsToday: PropTypes.number.isRequired,
        AlliedSupplyToday: PropTypes.number.isRequired,
        AxisSupplyToday: PropTypes.number.isRequired,
    }).isRequired,
};

export default DisplayWarEffort;
