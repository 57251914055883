import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import RemainingSupplyGraph from '../../components/RemainingSupplyGraph';
import Carousel from 'react-bootstrap/Carousel';
import getPhotoMapping from '../../components/getPhotoMapping';

function ObjectiveDetails() {
  const { state } = useLocation();
  const { name } = useParams();

  const objective = state?.objective;

  if (!objective || objective.Name !== decodeURIComponent(name)) {
    return <div>Objective not found.</div>;
  }

  const photos = getPhotoMapping().getReconPhotos(objective.Name);

  return (
    <div className="container">
      <h2 className="text-start my-3">{objective.Name}</h2>
      <h4 className='text-start'>{objective.Country}</h4>
      <h4 className="text-start text-capitalize">{objective.Type.charAt(0) + objective.Type.slice(1).toLowerCase() || 'N/A'}</h4>

      <Carousel>
        {photos.length > 0 ? (
          photos.map((photo, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/images/recon-photos/big-normandy/${photo}`}
                alt={`Recon of ${objective.Name}`}
              />
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/images/recon-photos/NoReconPhotoAvailable.png`}
              alt="No Recon Photo Available"
            />
          </Carousel.Item>
        )}
      </Carousel>

   

      <div className="my-3">
        <RemainingSupplyGraph
          supplyLevel={objective.SupplyLevel}
          maxSupplyLevel={objective.MaxSupplyLevel || 200}
        />
      </div>

      <table className="table table-dark table-bordered table-striped">
        <thead>
          <tr>
            <th>Current Supply</th>
            <th>Max Supply</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{objective.SupplyLevel || 'N/A'}</td>
            <td>{objective.MaxSupplyLevel || 'N/A'}</td>
            <td className="text-capitalize">{objective.Type.charAt(0) + objective.Type.slice(1).toLowerCase()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ObjectiveDetails;
