import React from 'react';
import PropTypes from 'prop-types';


function MissionCalendar({ missionDay, year, month }) {
  const daysInMonth = new Date(year, month, 0).getDate();
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay();

  // Generate calendar grid
  const calendarDays = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    calendarDays.push(<td key={`empty-${i}`} className="empty"></td>);
  }

  for (let day = 1; day <= daysInMonth; day++) {
    calendarDays.push(
      <td
        key={`day-${day}`}
        className={`text-center ${day === missionDay ? 'mission-day' : ''}`}
      >
        {day === missionDay ? 'X' : day}
      </td>
    );
  }

  while (calendarDays.length % 7 !== 0) {
    calendarDays.push(<td key={`empty-end-${calendarDays.length}`} className="empty"></td>);
  }

  const weeks = [];
  for (let i = 0; i < calendarDays.length; i += 7) {
    weeks.push(<tr key={`week-${i / 7}`}>{calendarDays.slice(i, i + 7)}</tr>);
  }

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  return (
    <div className="calendar-container">
      <div className="text-center text-light fw-bold">{`${monthNames[month - 1]} ${year}`}</div>
      <table className="table table-dark table-bordered calendar text-light">
        <thead>
          <tr>
            <th>S</th>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            <th>T</th>
            <th>F</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>{weeks}</tbody>
      </table>
    </div>
  );
}

MissionCalendar.propTypes = {
  missionDay: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
};

export default MissionCalendar;
