import React from 'react';
import PropTypes from 'prop-types';

function DisplaySquadStreaksList({ coalition, squads }) {
    // Sort the squads by MissionStreak in descending order and get the top 5
    const sortedSquads = [...squads].sort((a, b) => b.MissionStreak - a.MissionStreak);
    const squadTopFive = sortedSquads.slice(0, 5);

    const renderSquadRows = (squads) => {
        return squads.map((squad, index) => (
            <tr key={index}>
                <td>{squad.SquadName}</td>
                <td>{squad.MissionStreak}</td>
            </tr>
        ));
    };

    return (
        <>
            <div className="text-start text-uppercase">{coalition} Squads</div>
            <div className="accordion accordion-flush accordion-background" id={`squadAccordion-${coalition}`}>
                <div className="accordion-item accordion-background">
                    <div id={`squadCollapseOne-${coalition}`} className="accordion-collapse show">
                        <table className="table table-dark table-responsive table-striped table-bordered text-start">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="airfield-details-icon-column">Streak</th>
                                </tr>
                            </thead>
                            <tbody>{renderSquadRows(squadTopFive)}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

DisplaySquadStreaksList.propTypes = {
    coalition: PropTypes.oneOf(['Allied', 'Axis']).isRequired, // Only "Allied" or "Axis"
    squads: PropTypes.arrayOf(
        PropTypes.shape({
            SquadName: PropTypes.string.isRequired,
            MissionStreak: PropTypes.number.isRequired
        })
    ).isRequired
};

export default DisplaySquadStreaksList;
