import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../Modal';
import "../../styles/DisplayPilotStreaksList/display-pilot-streaks-list.css";

function DisplayPilotStreaksList({ pilotTopFive, coalition }) {
    const [selectedPilot, setSelectedPilot] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handlePilotClick = (pilotName) => {
        fetchPilotData(pilotName);
    };

    const fetchPilotData = (pilotName) => {
        const jsonUrl = `https://m2.combatbox.net/big-normandy-campaign/${encodeURIComponent(pilotName)}.json`;

        fetch(jsonUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setModalData(data);
                setSelectedPilot(pilotName);
                setShowModal(true);
            })
            .catch(() => {
                setModalData({ PlayerName: pilotName, error: true });
                setShowModal(true);
            });
    };

    const renderPilotRows = (pilots, isSelectable) => {
        return pilots.map((pilot, index) => (
            <tr
                key={index}
                className={isSelectable ? 'selectable-row' : ''}
                onClick={isSelectable ? () => handlePilotClick(pilot.Name) : null}
                style={{ cursor: isSelectable ? 'pointer' : 'default' }}
            >
                <td>{pilot.Name}</td>
                <td>{pilot.SuccessfulMissionStreak}</td>
                <td>{Math.min(Math.round(pilot.StreakBonus * 100), 100)}%</td>
            </tr>
        ));
    };

    return (
        <>
            <div className='text-start text-uppercase'>{coalition} Pilots</div>
            <div className="accordion accordion-flush accordion-background" id={`pilotAccordion-${coalition}`}>
                <div className="accordion-item accordion-background">
                    <div id={`pilotCollapseOne-${coalition}`} className="accordion-collapse show">
                        <table className="table table-dark table-responsive table-striped table-bordered text-start">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className='airfield-details-icon-column'>Streak</th>
                                    <th className='airfield-details-icon-column'>Bonus</th>
                                </tr>
                            </thead>
                            <tbody>{renderPilotRows(pilotTopFive, true)}</tbody>
                        </table>
                    </div>
                </div>
            </div>

            <CustomModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={modalData?.PlayerName || 'Pilot Info'}
                body={
                    modalData?.error ? (
                        <p>No story for this pilot.</p>
                    ) : modalData ? (
                        <>
                            <p><strong>Aircraft Type:</strong> {modalData.AircraftType || 'N/A'}</p>
                            <p><strong>Country:</strong> {modalData.Country || 'N/A'}</p>
                            <p><strong>Coalition:</strong> {modalData.Coalition || 'N/A'}</p>
                            <table className="table table-bordered table-dark table-striped">
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Time</th>
                                        <th>Position</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalData.Events?.map((event, idx) => (
                                        <tr key={idx}>
                                            <td>{event.EventType || 'N/A'}</td>
                                            <td>{event.DateTime || 'N/A'}</td>
                                            <td>{event.Position || 'N/A'}</td>
                                            <td>{event.AirfieldName || event.EnemyType || 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <p>Loading pilot data...</p>
                    )
                }
                size="lg"
            />
        </>
    );
}

DisplayPilotStreaksList.propTypes = {
    pilotTopFive: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string.isRequired,
            StreakBonus: PropTypes.number.isRequired,
        })
    ).isRequired,
    coalition: PropTypes.string.isRequired,
};

export default DisplayPilotStreaksList;
