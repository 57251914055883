const getPhotoMapping = () => {
  const imagesMap = {
    "Abbeville Drucat Airfield": ["Abbeville Drucat Airfield.jpg"],
    "Rouen Rail Yards": [
      "Rouen Rail Yards - Zone 1.jpg",
      "Rouen Rail Yards - Zone 2.jpg",
      "Rouen Rail Yards - Zone 3.jpg"
    ],
    "Carpiquet": ["Carpiquet Airfield.jpg"],
    "La Vielle": ["La Vielle Airfield.jpg"],
    "Pontorson": ["Pontorson Airfield.jpg"],
    "Triqueville": ["Trequeville Airfield.jpg"],
    "Maupertus": ["Maupertus Airfield.jpg"],
    "Lonrai": ["Lonrai Airfield.jpg"],
    "Cretteville": ["Cretteville Airfield.jpg"],
    "Le Havre Octeville": ["Le Havre Octeville Airfield.jpg"],
    "Beaumont Le Roger": ["Beaumont le Roger Airfield.jpg"],
    "Saint-Valery-en-Caux Flak School": ["Saint Valery-en-Caux Flak.jpg"],
    "Bolbec Aviation Fuel Logistics": ["Bolbec Aviation Fuel Logistics.jpg"],
    "Arromanches Mulberry B": ["Arromanches Mulberry B.jpg"],
    "Cabourg Coastal Defences": ["Cabourg Coastal Defences.jpg"],
    "Lisieux Ball Bearing Factory": ["Lisieux Ball Bearing Factory.jpg"],
    "Coutances Road and Rail Junction": ["Coutances Road and Rail Junction.jpg"],
    "Villedieu Fuel Depot": ["Villedieu Fuel Depot.jpg"],
    "Vire Command And Logistics": ["Vire Command and Logistics.jpg"],
    "Conde sur Noireau Tank Repair Station": ["Conde Sur Noireau Tank Re-Enforcements.jpg"],
    "Falaise Encampment": ["Falaise Troop Encampment.jpg"],
    "Vimoutiers Sawmill and Logging Station": ["Vimoutiers Sawmill.jpg"],
    "L Aigle Supply Dump": ["LAigle supply Dump.jpg"],
    "Argentan Switching Yard": ["Argentan.jpg"],
    "Saint Hilaire River Crossing": ["Saint Hilaire River Crossing.jpg"],
    "La Ferte Mace Troop Encampment": ["Le Ferta Mace Troop Encampment.jpg"],
    "Domfront HFDF Site": ["Domfront HFDF Site.jpg"],
    "Perche HFDF Testing Facility": ["Perch HFDF Testing Facility.jpg"],
    "fw190a6": ["fw 190 a-6.png"],
    "fw190a8": ["fw 190 a-8.png"],
    "bf109g6late": ["bf 109 g-6 late.png"],
    "bf109g14": ["bf 109 g-14.png"],
    "bf110g2": ["bf 110 g-2.png"],
    "me410a1": ["me 410 a-1.png"],
    "bf109g6as": ["bf 109 g-6as.png"],
    "ju88a4": ["ju 88 a-4.png"],
    "ju88c6": ["ju 88 c-6.png"],
    "spitfiremkixc": ["spitfire mk.ixc.png"],
    "typhoonmkib": ["typhoon mk.ib.png"],
    "spitfiremkixe": ["spitfire mk.ixe.png"],
    "p51b5": ["p-51b-5.png"],
    "a20b": ["a-20b.png"],
    "mosquitofbmkvis2": ["mosquito f.b. mk.vi ser.2.png"],
    "c47a": ["c-47a.png"],
    "fw190a6": ["fw 190 a-6.png"],
    "fw190a8": ["fw 190 a-8.png"],
    "p47d22": ["p-47d-22.png"],
    "p47d28": ["p-47d-28.png"],
    "p38j25": ["p-38j-25.png"],
    "p51d15": ["p-51d-15.png"],
    "ju523mg4e": ["ju 52 3mg4e.png"]

    // Add more airfields and their images as needed
  };

  return {
    getReconPhotos: (objectiveName) => imagesMap[objectiveName] || []
  };
};

export default getPhotoMapping;
