import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MissionCalendar from '../components/MissionCalendar';

function DisplayCampaignStatus({ campaignData }) {
    const [remainingTime, setRemainingTime] = useState('00:00:00');

    useEffect(() => {
        if (campaignData.EstimatedMissionEnd) {
            const missionEndTime = new Date(campaignData.EstimatedMissionEnd); // Parse ISO 8601 time

            const updateRemainingTime = () => {
                const now = new Date();
                let timeLeft = missionEndTime - now; // Time difference in milliseconds

                if (timeLeft <= 0) {
                    setRemainingTime('00:00:00'); // If mission has ended
                    return;
                }

                const hours = Math.floor(timeLeft / (1000 * 60 * 60));
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

                setRemainingTime(
                    `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
                );
            };

            updateRemainingTime(); // Initial call to set time immediately
            const intervalId = setInterval(updateRemainingTime, 1000); // Update every second

            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        }
    }, [campaignData.EstimatedMissionEnd]);

    const formatDate = () => {
        const day = campaignData.Day.Day.toString().padStart(2, '0');
        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const month = monthNames[campaignData.Day.Month - 1];
        const year = campaignData.Day.Year;
        return `${day}-${month}-${year}`;
    };

    const formatISOTime = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    };

    return (
        <div className="campaign-status-container">
            CAMPAIGN STATUS
            <div className='border p-2'>
                <div className='row'>
                <div className='col-lg-7 col-12'>
                    <table className="table table-sm table-responsive table-borderless">
                        <tbody>
                            <tr>
                                <th className="bg-transparent text-white campaign-status-title-column" scope="row">Remaining Mission Time</th>
                                <td className="bg-transparent text-white airfield-details-icon-column ">{remainingTime}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white campaign-status-title-column" scope="row">Campaign Day</th>
                                <td className="bg-transparent text-white airfield-details-icon-column">{campaignData.Day.DayInCampaign}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white campaign-status-title-column" scope="row">Campaign Date</th>
                                <td className="bg-transparent text-white airfield-details-icon-column">{formatDate()}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white campaign-status-title-column" scope="row">Mission Start Time</th>
                                <td className="bg-transparent text-white airfield-details-icon-column">{campaignData.CampaignDayMissionStartTime}</td>
                            </tr>
                            <tr>
                                <th className="bg-transparent text-white campaign-status-title-column" scope="row">Days Remaining</th>
                                <td className="bg-transparent text-white airfield-details-icon-column">{campaignData.DaysRemaining}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-lg-5 col-12 '>
                    <MissionCalendar
                        missionDay={campaignData.Day.Day}
                        year={campaignData.Day.Year}
                        month={campaignData.Day.Month}
                    />
                </div>
                </div>
            </div>
        </div>
    );
}

DisplayCampaignStatus.propTypes = {
    campaignData: PropTypes.shape({
        CampaignDayMissionStartTime: PropTypes.string.isRequired,
        MissionEndTimeUTC: PropTypes.string.isRequired,
        Day: PropTypes.shape({
            Day: PropTypes.number.isRequired,
            Month: PropTypes.number.isRequired,
            Year: PropTypes.number.isRequired,
            DayInCampaign: PropTypes.number.isRequired,
        }).isRequired,
        DaysRemaining: PropTypes.number.isRequired,
    }).isRequired,
};

export default DisplayCampaignStatus;
