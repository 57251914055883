import React, { useState, useEffect } from 'react';
import CustomModal from './Modal'; // Import your custom modal

function MapSlider({ maxDay }) {
    const [sliderValue, setSliderValue] = useState(maxDay);
    const [isAnimating, setIsAnimating] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [intervalId, setIntervalId] = useState(null);

    const formattedValue = String(sliderValue).padStart(3, '0');
    const imageUrl = `https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-day-${formattedValue}-1200.jpg`;

    useEffect(() => {
        preloadCampaignImages(maxDay);
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [maxDay, intervalId]);

    const preloadCampaignImages = (maxDay) => {
        const baseImageUrl = 'https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-day-';
        const imageExtension = '-1200.jpg';
        for (let day = 1; day <= maxDay; day++) {
            const formattedDay = String(day).padStart(3, '0');
            const img = new Image();
            img.src = `${baseImageUrl}${formattedDay}${imageExtension}`;
        }
    };

    const toggleAnimation = () => {
        if (isAnimating) {
            clearInterval(intervalId);
            setIsAnimating(false);
        } else {
            const id = setInterval(() => {
                setSliderValue((prev) => (prev < maxDay ? prev + 1 : 1));
            }, 200);
            setIntervalId(id);
            setIsAnimating(true);
        }
    };

    const handleSliderChange = (e) => {
        setSliderValue(parseInt(e.target.value));
    };

    const openImageModal = () => {
        setModalImageUrl(`https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-day-${formattedValue}.jpg`);
        setShowModal(true);
    };

    return (
        <div className="row justify-content-center">
            <div className="col-12" id="dayDisplay">Day: {sliderValue}</div>
            <input
                type="range"
                min="1"
                max={maxDay}
                value={sliderValue}
                onChange={handleSliderChange}
                className="form-range"
                id="myRange"
            />
            <div className='col-12 text-center'>
                <button onClick={toggleAnimation} className="btn btn-primary mt-2">
                    {isAnimating ? 'Stop Animation' : 'Start Animation'}
                </button>
            </div>
            <img
                id="campaignImage"
                src={imageUrl}
                alt={`Campaign Day ${sliderValue}`}
                className="img-fluid mt-3"
                onClick={openImageModal}
            />

            <CustomModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={`Day ${sliderValue}`}
                body={<img src={modalImageUrl} alt={`Day ${sliderValue}`} className="img-fluid" />}
                size="xxl"
            />
        </div>
    );
}

export default MapSlider;
