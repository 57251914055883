// src/pages/Pilots.jsx
import React from 'react';
import PilotStatsList from '../components/PilotStatsList'; // Adjust path as necessary

function Pilots() {
  return (
    <div>
      <PilotStatsList />
    </div>
  );
}

export default Pilots;
