import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RemainingSupplyGraph from '../RemainingSupplyGraph'; // Import your component

function DisplayAirfieldsList({ airfields }) {
  const renderRows = () => {
    return airfields.map((item, index) => {
      const arrowStyle = { transform: `rotate(${item.RunwayBearing}deg)` };

      return (
        <tr key={index}>
          <th scope="row">
            <Link to={`/airfield/${encodeURIComponent(item.Name)}`} state={{ airfield: item }} className="text-white text-decoration-none">
              {item.Name}
            </Link>
          </th>
          <td>{item.Country}</td>
          <td>
            <RemainingSupplyGraph
              supplyLevel={item.SupplyLevel}
              maxSupplyLevel={item.MaxSupplyLevel || 200}
            />
          </td>
          <td>
            <i className="fa-solid fa-arrow-up" style={arrowStyle} title={`Runway Bearing: ${item.RunwayBearing}°`}></i>
            <span className='ps-2'>{item.RunwayBearing}°</span>
          </td>
          <td>{item.RunwayIsConcrete ? 'Concrete' : 'Grass'}</td>
        </tr>
      );
    });
  };

  return (
    <table className="table table-bordered table-dark table-responsive table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Country</th>
          <th scope="col">Supply</th>
          <th scope="col">Runway</th>
          <th scope="col">Type</th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
}

DisplayAirfieldsList.propTypes = {
  airfields: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Country: PropTypes.string.isRequired,
      SupplyLevel: PropTypes.number.isRequired,
      MaxSupplyLevel: PropTypes.number,
      RunwayBearing: PropTypes.number.isRequired,
      RunwayIsConcrete: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default DisplayAirfieldsList;
