import React from 'react';
import PropTypes from 'prop-types';

function DisplayWeatherForecast({ weather }) {
    const parseBearing = (windDescription) => {
        const match = windDescription.match(/(\d{1,3})°/);
        return match ? parseInt(match[1], 10) : 0;
    };

    const getQWeatherTemperatureIcon = (temperature) => {
        if (temperature <= 0) return 'qi-2217'; // Freezing or below
        if (temperature > 0 && temperature <= 20) return 'fa-solid fa-temperature-half'; // Mild
        return 'qi-hot'; // Hot
    };

    const getCloudIcon = (description) => {
        description = description.toLowerCase();
        if (description.includes('clear')) return 'wi wi-day-sunny';
        if (description.includes('partly')) return 'wi wi-day-cloudy';
        if (description.includes('overcast')) return 'wi wi-cloudy';
        if (description.includes('rain')) return 'wi wi-rain';
        if (description.includes('storm')) return 'wi wi-thunderstorm';
        return 'wi wi-cloud';
    };

    const currentWeather = weather.Weather;
    const tomorrowWeather = weather.WeatherTomorrow;

    return (
        <div className="weather-forecast-container">
            {/* Today's Forecast */}
            WEATHER REPORT
            <div className='border p-2'>
            <div class="col-12 text-capitalize fw-bolder text-start border-bottom">
                Today's forecast
            </div>
            <div>
                <table class="table table-sm table-responsive table-borderless">
                    <tbody>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Temperature</th>
                            <td class="bg-transparent text-white"><i className={getQWeatherTemperatureIcon(currentWeather.Temperature)}></i> {currentWeather.Temperature}°C</td>
                        </tr>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Clouds</th>
                            <td class="bg-transparent text-white text-capitalize"><i className={getCloudIcon(currentWeather.CloudDescription)}></i> {currentWeather.CloudDescription}</td>
                        </tr>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Winds</th>
                            <td class="bg-transparent text-white"><i className={`wi wi-wind from-${Math.round(parseBearing(currentWeather.WindDescription) / 10) * 10}-deg`}></i> {currentWeather.WindDescription}</td>
                        </tr>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Precipitation</th>
                            <td class="bg-transparent text-white">None</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <div class="col-12 text-capitalize fw-bolder text-start border-bottom">
                    Tomorrow's forecast
                </div>
                <table class="table table-sm table-responsive table-borderless">
                    <tbody>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Temperature</th>
                            <td class="bg-transparent text-white" ><i className={getQWeatherTemperatureIcon(tomorrowWeather.Temperature)}></i> {tomorrowWeather.Temperature}°C</td>
                        </tr>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Clouds</th>
                            <td class="bg-transparent text-white text-capitalize"><i className={getCloudIcon(tomorrowWeather.CloudDescription)}></i> {tomorrowWeather.CloudDescription}</td>
                        </tr>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Winds</th>
                            <td class="bg-transparent text-white"><i className={`wi wi-wind from-${Math.round(parseBearing(tomorrowWeather.WindDescription) / 10) * 10}-deg`}></i> {tomorrowWeather.WindDescription}</td>
                        </tr>
                        <tr>
                            <th class="bg-transparent text-white" scope="row">Precipitation</th>
                            <td class="bg-transparent text-white">None</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    );
}

DisplayWeatherForecast.propTypes = {
    weather: PropTypes.shape({
        Weather: PropTypes.shape({
            Temperature: PropTypes.number.isRequired,
            CloudDescription: PropTypes.string.isRequired,
            WindDescription: PropTypes.string.isRequired,
        }).isRequired,
        WeatherTomorrow: PropTypes.shape({
            Temperature: PropTypes.number.isRequired,
            CloudDescription: PropTypes.string.isRequired,
            WindDescription: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default DisplayWeatherForecast;
