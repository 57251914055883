import React, { useEffect, useState } from 'react';
import axios from 'axios';

function PilotStatsList() {
  const [pilotStats, setPilotStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'streak_current', direction: 'descending' });

  const pilotsPerPage = 50;
  

  useEffect(() => {
    const fetchPilotStats = async () => {
      try {
        const response = await axios.get('https://il2statsapi.combatbox.net/api/ironmanstats');

        console.log(response.data);
        
        // Filter data where 'dead' is 0
        const filteredData = response.data.filter(pilot => pilot.dead === 0 && pilot.captured === 0);
        
        setPilotStats(filteredData); // Set only filtered data
        console.log('Filtered Pilot Stats:', filteredData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching pilot stats:', err); // Log error if fetching fails
      } finally {
        setLoading(false);
      }
    };
  
    fetchPilotStats();
  }, []);
  


  const formatFlightTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const getCoalitionName = (coalPref) => {
    switch (coalPref) {
      case 0:
        return (<div><img
          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/allies.svg`}
          style={{ width: '20px', cursor: 'pointer' }}
        />
          <img
            src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/axis.svg`}
            style={{ width: '20px', cursor: 'pointer' }}
          />
        </div>);
      case 1:
        return (<img
          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/allies.svg`}
          style={{ width: '20px', cursor: 'pointer' }}
        />);
      case 2:
        return (<img
          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/axis.svg`}
          style={{ width: '20px', cursor: 'pointer' }}
        />);
      default:
        return 'Unknown';
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPilots = [...pilotStats].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredPilots = sortedPilots.filter((pilot) =>
    pilot.nickname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedPilots = filteredPilots.slice(
    (currentPage - 1) * pilotsPerPage,
    currentPage * pilotsPerPage
  );

  const getSortArrow = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (e) => {
    const page = Number(e.target.value);
    if (page >= 1 && page <= Math.ceil(filteredPilots.length / pilotsPerPage)) {
      setCurrentPage(page);
    }
  };

  const getHeaderWithIcon = (key, label, icon) => (
    <>
      {icon && (
        <img
          src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/${icon}`}
          style={{ width: '20px', marginRight: '5px', cursor: 'pointer' }}
          alt={`${label} icon`}
          title={label} // Tooltip text on hover
        />
      )}
      {getSortArrow(key)}
    </>
  );


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div className="container">
      <h2 className="text-uppercase fw-bolder text-center pt-3">
          Pilot stats
        </h2>

        <div className="mb-3">
          <input
            type="text"
            placeholder="Search by Pilot Name"
            value={searchQuery}
            onChange={handleSearchChange}
            className="form-control"
          />
        </div>
      </div>
      <div className='container-fluid pilot-stats'>
        <div className="table-responsive">
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th onClick={() => handleSort('nickname')}>{getHeaderWithIcon('nickname', 'Pilot Name', 'human.png')}</th>
                <th onClick={() => handleSort('coal_pref')}>{getHeaderWithIcon('coal_pref', 'Coalition', '')}</th>
                <th onClick={() => handleSort('streak_current')}>{getHeaderWithIcon('streak_current', 'Air Kills', 'ak.png')}</th>
                <th onClick={() => handleSort('streak_ground_current')}>{getHeaderWithIcon('streak_ground_current', 'Ground Kills', 'gk.png')}</th>
                <th onClick={() => handleSort('takeoff')}>{getHeaderWithIcon('takeoff', 'Missions', 'missions.png')}</th>
                <th onClick={() => handleSort('flight_time')}>{getHeaderWithIcon('flight_time', 'Flight Time', 'ft.png')}</th>
                <th onClick={() => handleSort('khr')}>{getHeaderWithIcon('khr', 'Air Kills Per Hour', 'khr.png')}</th>
                <th onClick={() => handleSort('gkh')}>{getHeaderWithIcon('gkh', 'Ground Kills Per Hour', 'ground_kills_hour.png')}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedPilots.map((pilot, index) => (
                <tr key={index}>
                  <td>{pilot.nickname}</td>
                  <td className="text-center">{getCoalitionName(pilot.coal_pref)}</td>
                  <td>{pilot.ak_total}</td>
                  <td>{pilot.gk_total}</td>
                  <td>{pilot.sorties_total}</td>
                  <td>{formatFlightTime(pilot.flight_time)}</td>
                  <td>{pilot.khr}</td>
                  <td>{pilot.gkhr}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

  

      <div className="d-flex justify-content-center align-items-center mt-3 mb-5">
        <button
          className="btn btn-primary me-5"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        <div className="d-flex align-items-center">
          <span>Page </span>
          <input
            type="number"
            value={currentPage}
            onChange={handlePageChange}
            className="form-control mx-2"
            style={{ width: '60px' }}
            min={1}
            max={Math.ceil(filteredPilots.length / pilotsPerPage)}
          />
          <span> of {Math.ceil(filteredPilots.length / pilotsPerPage)}</span>
        </div>

        <button
          className="btn btn-primary ms-5"
          onClick={() =>
            setCurrentPage((prev) =>
              prev < Math.ceil(filteredPilots.length / pilotsPerPage) ? prev + 1 : prev
            )
          }
          disabled={currentPage === Math.ceil(filteredPilots.length / pilotsPerPage)}
        >
          Next
        </button>
      </div>
      </div>
    </div>
  );
}

export default PilotStatsList;
