import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import "../styles/Modal/modal.css";

function CustomModal({ show, onHide, title, body, size }) {
  const customClass = size === 'xxl' ? 'modal-xxl' : '';

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      centered 
      dialogClassName={customClass} 
      size={size !== 'xxl' ? size : undefined} 
    >
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        {body}
      </Modal.Body>
      <Modal.Footer className="bg-dark text-white">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CustomModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['sm', 'lg', 'xl', 'xxl', null]),
};

CustomModal.defaultProps = {
  size: null,
};

export default CustomModal;
