import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/RemainingSupplyGraph/remaining-supply-graph.css';

function RemainingSupplyGraph({ supplyLevel, maxSupplyLevel }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 992px)'); // lg breakpoint (Bootstrap default)
    const handleResize = () => setIsSmallScreen(mediaQuery.matches);

    mediaQuery.addEventListener('change', handleResize);
    handleResize(); // Set initial value

    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const calculateBarColor = (value) => {
    const green = [40, 167, 69];
    const red = [220, 53, 69];
    const r = Math.round(red[0] + ((green[0] - red[0]) * (value / 100)));
    const g = Math.round(red[1] + ((green[1] - red[1]) * (value / 100)));
    const b = Math.round(red[2] + ((green[2] - red[2]) * (value / 100)));
    return `rgb(${r}, ${g}, ${b})`;
  };

  const currentSupplyPercent = (supplyLevel / maxSupplyLevel) * 100;

  return (
    <div className="supply-bar-container">
      <div
        className="supply-bar"
        style={{
          width: `${currentSupplyPercent}%`,
          backgroundColor: calculateBarColor(currentSupplyPercent),
        }}
      ></div>
      <div className="supply-bar-airfield-overlay">
        {isSmallScreen ? `${supplyLevel}` : `${supplyLevel} / ${maxSupplyLevel}`}
      </div>
    </div>
  );
}

RemainingSupplyGraph.propTypes = {
  supplyLevel: PropTypes.number.isRequired,
  maxSupplyLevel: PropTypes.number.isRequired,
};

export default RemainingSupplyGraph;
